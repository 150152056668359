import React from 'react';
import './Error.css';
import logo from '../images/logo.svg';
import errorvector from '../images/error-vector.svg';


function Error(props) {
    return (
        <div>
            <div className="error-flexbox-container">
                <div className="error-navbar">
                    <div className="error-nav-gradient"></div>
                    <div className="error-logo">
                        <img data-testid="logo-img" src={logo} alt="Symetra Logo" />
                    </div>
                </div>
                <div className="error-container">
                    <div className="error-vector">
                        <img data-testid="warning-icon" src={errorvector} alt="warning icon" />
                    </div>
                    <div className="error">
                        <div data-testid="error-title" className="error-title">
                            <p>Oops! Something went wrong</p>
                        </div>
                        <div data-testid="error-subtitle" className="error-subtitle">
                            <p>The page isn't redirecting properly</p>
                        </div>
                        <a id="loginLink" href={props.loginUrl} data-testid="loginLink" className="error-btn-master">
                            Return to login
                        </a>

                        <div data-testid="error-message" className="error-type">
                            <p>{props.message}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Error;