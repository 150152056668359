import React, { useState } from 'react';
import logo from '../images/logo.svg';
import loader from '../images/loader.png';
import { useEffect } from 'react';
import { CognitoAuthService } from 'pexcog';
import Error from './Error';
import './Landing.css';

function Landing() {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const initLogin = async () => {
        try {
            const authService = new CognitoAuthService({
                appClientId: process.env.REACT_APP_CLIENT_ID,
                appLoginUrl: process.env.REACT_APP_LOGIN_URL,
                appMainUrl: process.env.REACT_APP_MAIN_URL,
                ssoLoginUrl: process.env.REACT_APP_SSO_LOGIN_URL,
                tokenUrl: process.env.REACT_APP_TOKEN_URL,
                cookieSignerUrl: process.env.REACT_APP_COOKIE_URL,
                commonDomain: process.env.REACT_APP_COMMON_DOMAIN
            });
            if (authService.isRedirectFromIdentityProvider()) {
                await authService.authorizeUser();
                await authService.setCloudFrontSignedCookies();
                authService.redirectToMainApp();
            } else {
                authService.authenticateUser();
            }
        } catch (error) {
            setHasError(true);
            setErrorMessage(`${error}`);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            initLogin();
        }, 4000);
    }, []);

    return (
        <div>
            {!hasError && (
                <div>
                    <p>This is {process.env.REACT_APP_ENV} environment</p>
                    <div className="landing-flexbox-container">
                        <div className="landing-flexbox-item">
                            <div className="landing-spinner-container">
                                <span className="landing-spinner">
                                    <img src={loader} alt="loading indicator" />
                                </span>
                            </div>
                            <div className="landing-content" data-testid="content-title">You are being redirected to a Symetra.com application</div>
                            <div className="landing-logo">
                                <img src={logo} alt="content logo" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {hasError && <Error message={errorMessage} loginUrl={process.env.REACT_APP_LOGIN_URL}></Error>}
        </div>
    );
}

export default Landing;